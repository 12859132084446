import React from "react";
import "./experience.css";
import { AiOutlineHtml5 } from "react-icons/ai";
import {
  DiAngularSimple,
  DiCss3,
  DiCss3Full,
  DiHeroku,
  DiJava,
  DiJavascript,
  DiJqueryUiLogo,
  DiLinux,
  DiMongodb,
  DiNodejs,
  DiNpm,
  DiReact,
  DiVisualstudio,
} from "react-icons/di";
import { IoLogoJavascript } from "react-icons/io";
import { FaGitlab, FaReact } from "react-icons/fa";
import { GrNode, GrOracle } from "react-icons/gr";
import {
  SiAndroidstudio,
  SiAngularjs,
  SiAuth0,
  SiExpress,
  SiJquery,
  SiMaterialui,
  SiSass,
  SiSqlite,
  SiTypescript,
} from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { GiBreakingChain } from "react-icons/gi";
import { BsBootstrap, BsGithub, BsNodeMinus } from "react-icons/bs";
import { BiExpand } from "react-icons/bi";

const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>
      <h5>Languages</h5>

      <div className="container experience__container littlepadding">
        <div className="experience__icon">
          <DiJava className="icon" />
          <IoLogoJavascript className="icon" />
          <SiTypescript className="icon" />
          <AiOutlineHtml5 className="icon" />
          <DiCss3Full className="icon" />
          {/* <span>EJS</span>
          <span>SQL</span> */}
        </div>
      </div>
      {/* <h5>WEB 3.0</h5>
      <div className="container experience__container littlepadding">
        <div className="experience__icon">
           <span>Motoko Language</span>
          <span>Internet Computer Dfinity</span>
          <span>Crypto Token</span>
          <span>NFT</span> 
        </div>
      </div> */}
      <h5>Libraries and Frameworks</h5>
      <div className="container experience__container littlepadding">
        <div className="experience__icon">
          <DiReact className="icon" />
          <SiAngularjs className="icon" />
          <DiNodejs className="icon" />
          <SiExpress className="icon" />
          <BsBootstrap className="icon" />
          <SiJquery className="icon" />
          <DiJqueryUiLogo className="icon" />
          <SiMaterialui className="icon" />
        </div>
      </div>

      <h5> Database</h5>
      <div className="container experience__container littlepadding">
        <div className="experience__icon">
          <DiMongodb className="icon" />
          <GrOracle className="icon" />
          <SiSqlite className="icon" />
        </div>
      </div>

      <h5>Tools</h5>
      <div className="container experience__container littlepadding">
        <div className="experience__icon">
          <BsGithub className="icon" />
          <FaGitlab className="icon" />
          <DiVisualstudio className="icon" />
          <DiLinux className="icon" />
          <DiHeroku className="icon" />
          <SiSass className="icon" />
          <SiAndroidstudio className="icon" />
          <DiNpm className="icon" />
          <SiAuth0 className="icon" />
        </div>
      </div>
    </section>
  );
};

export default Experience;
