import React from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CV from "../../assets/cv.pdf"

function Btn() {
    return (
        <Stack className='btnh' spacing={2} direction="row">
            <Button style={{ color: "#4db5ff" }} variant="outlined" href={CV} download >Download CV</Button>
            <Button style={{ backgroundColor: "#4db5ff" }} href="https://www.linkedin.com/in/vishal-sharma-826065202/?originalSubdomain=in" variant="contained">Let's Talk</Button>
        </Stack>
    )
}

export default Btn