import React, { useEffect } from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
// import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Contact from "./components/contact/Contact";
// import Testimonials from "./components/testimonals/Testimonials"
import Footer from "./components/footer/Footer";
import Background from "./components/particles/Background";
import Favicon from "react-favicon";
import favi from "./assets/favicon-32x32.png";
import { MutatingDots } from "react-loader-spinner";
import "./App.css";
import AnimatedCursor from "react-animated-cursor";
const App = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <div className="loader__css">
      <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: "#2c2c6c",
        }}
        outerStyle={{
          border: "3px solid #4db5ff",
        }}
      />
      <MutatingDots
        height="100"
        width="100"
        color="#4db5ff"
        secondaryColor="#4db5ff"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  ) : (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: "#2c2c6c",
        }}
        outerStyle={{
          border: "3px solid #4db5ff",
        }}
      />
      <Favicon url={favi} />
      <Background />
      <Header />
      <Nav />
      <About />
      <Experience />
      {/* <Services /> */}
      <Portfolio />
      <Contact />
      {/* <Testimonials/> */}
      <Footer />
    </>
  );
};

export default App;
