import React from "react";
import Button from "@mui/material/Button";
import "./portfolio.css";
import tokenImg from "../../assets/token.png";
import ecommerce from "../../assets/ecommerce.png";
import keeper from "../../assets/keeper.png";
import daily from "../../assets/Dailyjounery.png";
import job from "../../assets/job.png";
import tin from "../../assets/tindog.png";
import canva_blog from "../../assets/canva_blog.png";
import todo from "../../assets/todo.png";
import drum from "../../assets/drum.png";
import dice from "../../assets/dice_game.png";
import fespoint from "../../assets/Fespoints.png";
import react_1 from "../../assets/React-template-1.png";
import react_2 from "../../assets/React-template-2.png";

//use map function
const Portfolio = () => {
  const data_portfolio = [
    {
      key: 11,
      title: "Fespoints | Reward App(MERN Stack)",
      img: fespoint,
      demo: "https://fespoints.site/auth/sign-in",
    },
    {
      key: 0,
      title: "NFT Token WEB 3.0 (BlockChain)",
      img: tokenImg,
      github: "https://github.com/Anshusharma17",
      demo: "https://agmtn-hiaaa-aaaao-aaq3q-cai.ic0.app/",
    },
    {
      key: 1,
      title: "E-Commerce Website (MERN)",
      img: ecommerce,
      github: "https://github.com/Anshusharma17/E-Commerce_College/tree/final",
      demo: "https://e-commerce-admin-client-api.onrender.com/",
    },
    {
      key: 12,
      title: "React Template 1 (Reactjs)",
      img: react_1,
      demo: "https://anshusharma17.github.io/react-template-demo-1/",
    },
    {
      key: 13,
      title: "React Template 2 (Reactjs)",
      img: react_2,
      demo: "https://anshusharma17.github.io/react-template-demo-3/",
    },
    {
      key: 2,
      title: "Google Keeper Clone (MERN)",
      img: keeper,
      github: "https://github.com/Anshusharma17/keeper-Notes",
      demo: "https://keeper-notes-rr1g.onrender.com/",
    },

    {
      key: 3,
      title: "Daily Journey Blog (ejs+node.js)",
      img: daily,
      github: "https://github.com/Anshusharma17/Daily-Journey",
      demo: "https://daily-journey.onrender.com/",
    },
    {
      key: 4,
      title: "Job Notification Subscription (node.js)",
      img: job,
      github: "https://github.com/Anshusharma17/Job-Notification-Subscription",
      demo: "https://job-notification-subscription.onrender.com/",
    },
    {
      key: 5,
      title: "Profile Website (BootStrap)",
      img: tin,
      github: "https://github.com/Anshusharma17/Profile-Website",
      demo: "https://anshusharma17.github.io/Profile-Website/",
    },
    {
      key: 6,
      title: "Todo List (Ejs+node.js)",
      img: todo,
      github: "https://github.com/Anshusharma17/To-do-List",
      demo: "https://to-do-list-gqi5.onrender.com/",
    },
    {
      key: 7,
      title: "Blog Website (Canva)",
      img: canva_blog,
      github: "https://github.com/Anshusharma17",
      demo: "https://anshu.my.canva.site/",
    },
    {
      key: 8,
      title: "Play Drum",
      img: drum,
      github: "https://github.com/Anshusharma17/The-Simon-Game",
      demo: "https://anshusharma17.github.io/The-Simon-Game/",
    },
    {
      key: 9,
      title: "The Dice Game",
      img: dice,
      github: "https://github.com/Anshusharma17/The-Dice-Game",
      demo: "https://anshusharma17.github.io/The-Dice-Game/",
    },
  ];

  const displayPortfolio = data_portfolio.map((item) => (
    <article className="portfolio__item" key={item.key}>
      <div className="portfolio__item-image">
        <img src={item.img} alt="" />
      </div>
      <h3>{item.title}</h3>
      <div className="pbtn">
        {item.github && (
          <Button
            style={{ color: "#4db5ff" }}
            className="btn1"
            variant="outlined"
            href={item.github}
            target="_blank"
          >
            Github
          </Button>
        )}
        <Button
          style={{ backgroundColor: "#4db5ff" }}
          className="btn2"
          variant="contained"
          href={item.demo}
          target="_blank"
        >
          Demo
        </Button>
      </div>
    </article>
  ));

  return (
    <section id="portfolio">
      <h5>MY Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">{displayPortfolio}</div>
    </section>
  );
};

export default Portfolio;
