import React from "react";
import "./about.css";
import ME from "../../assets/me-about.png";
import { MdWorkOutline } from "react-icons/md";
import { SiBlockchaindotcom } from "react-icons/si";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import Button from "@mui/material/Button";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <MdWorkOutline className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Year Working Experience </small>
            </article>
            <article className="about__card">
              <SiBlockchaindotcom className="about__icon" />
              <h5>Web 3.0 (Block-Chain)</h5>
              <small>3+ Project (DApps,Tokens,NFT) </small>
            </article>
            <article className="about__card">
              <AiOutlineFundProjectionScreen className="about__icon" />
              <h5>Project</h5>
              <small>25+ deploy Projects</small>
            </article>
          </div>
          <p>
            With over <strong>3 years</strong> of robust experience in{" "}
            <em>Full-Stack Web Development</em>, I position myself as your go-to
            professional freelancer. My expertise lies in specialized skills
            within <strong>MERN</strong> and <strong>MEAN</strong> stacks,
            showcasing a solid command of frameworks such as{" "}
            <strong>Express</strong> and <strong>Nest</strong> on the backend,
            and <strong>React</strong>,<strong>Angular</strong>,{" "}
            <strong>Tailwind</strong>, and <strong>Bootstrap</strong> on the
            frontend. Additionally, I adeptly handle data using{" "}
            <strong>MySQL</strong>, <strong>SQLite</strong>, and
            <strong>MongoDB</strong>. Beyond development, my proficiency extends
            to platforms like <strong>Bubble.io</strong>,
            <strong>WordPress</strong>, and various other technologies. As a
            freelancer, I don't merely deliver projects; I bring a holistic
            approach to every task, ensuring a comprehensive and effective
            solution tailored to your specific needs.
          </p>
          <Button
            style={{ backgroundColor: "#4db5ff" }}
            href="https://www.linkedin.com/in/vishal-sharma-826065202/?originalSubdomain=in"
            variant="contained"
          >
            Let's Talk
          </Button>
        </div>
      </div>
    </section>
  );
};

export default About;
