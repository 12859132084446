import React, { useRef } from "react";
import Button from "@mui/material/Button";
import "./contact.css";
import { FiMail } from "react-icons/fi";
import { RiMessengerLine } from "react-icons/ri";
import { AiOutlineWhatsApp } from "react-icons/ai";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_f8lhpyf",
      "template_25r0j87",
      form.current,
      "D9RzWytWZXo-BgeXU"
    );
    e.target.reset();
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact-container">
        <div className="contact__options">
          <article className="contact__option">
            <FiMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>anshusharma608218@gmail.com</h5>
            <Button href="mailto:anshusharma608218@gmail.com">
              Send a message
            </Button>
          </article>
          <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>Anshu Sharma</h5>
            <Button href="https://m.me/100009590627792">Send a message</Button>
          </article>
          <article className="contact__option">
            <AiOutlineWhatsApp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+918896xxxxxx</h5>
            <Button href="https://api.whatsapp.com/send?phone=918896538045">
              Send a message
            </Button>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            className="inptext"
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input
            className="inptext"
            type="email"
            name="email"
            placeholder="Your Email"
            required
          />
          <textarea
            className="inptext"
            type="message"
            name="message"
            rows={7}
            placeholder="Your Message"
            required
          />
          <Button
            style={{ backgroundColor: "#4db5ff" }}
            type="submit"
            variant="contained"
          >
            Send Message
          </Button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
