import React from "react";
import "./footer.css";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineInstagram } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { TbBrandLinkedin } from "react-icons/tb";

const Footer = () => {
  return (
    <section className="footer">
      <footer>
        <a href="#" className="footer__logo">
          Vishal Sharma
        </a>
        <ul className="permalinks">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#experience">Experience</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            <a href="https://anshusharma17.github.io/Anshusharma17/">
              My Certificate
            </a>
          </li>
        </ul>
        <div className="footer__socials">
          <a href="facebook">
            <FiFacebook />
          </a>
          <a href="">
            <AiOutlineInstagram />
          </a>
          <a href="">
            <FiTwitter />
          </a>
          <a href="https://www.linkedin.com/in/vishal-sharma-826065202/?originalSubdomain=in">
            <TbBrandLinkedin />
          </a>
        </div>
        <div className="footer__copyright">
          <small>
            &copy; Vishal Sharma {new Date().getFullYear()}-{new Date().getFullYear()+1}
          </small>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
