import React, { useState } from 'react'
import "./nav.css"
import { BiHome } from 'react-icons/bi'
import { BiUser } from 'react-icons/bi'
import { BsJournalBookmark } from 'react-icons/bs'
import { MdOutlinePermContactCalendar } from 'react-icons/md'
import { MdOutlineDesignServices } from 'react-icons/md'
const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  const [ani, setani] = useState(true);
  setTimeout(() => setani(false), 3000);


  return (
    <nav className={ani === true ? "first" : "second"} >
      <a href="#" onClick={() => setActiveNav("#")} className={activeNav === "#" && "active"}><BiHome /></a>
      <a href="#about" onClick={() => setActiveNav("#about")} className={activeNav === "#about" ? "active" : ""}><BiUser /></a>
      <a href="#experience" onClick={() => setActiveNav("#experience")} className={activeNav === "#experience" ? "active" : ""}><BsJournalBookmark /></a>
      <a href="#portfolio" onClick={() => setActiveNav("#services")} className={activeNav === "#services" ? "active" : ""}><MdOutlineDesignServices /></a>
      <a href="#contact" onClick={() => setActiveNav("#contact")} className={activeNav === "#contact" ? "active" : ""}><MdOutlinePermContactCalendar /></a></nav>
  )
}
export default Nav